import { __makeTemplateObject } from "tslib";
import * as React from 'react';
import styled from 'styled-components';
import { LightenDarkenColor } from '../../utils/Helpers';
import { ErrorOutlineIcon, InfoOutlineIcon, SuccessOutlineIcon, WarningOutlineIcon, CloseOutlineIcon, } from '../../components/icons';
var bgColor = {
    success: '#edf7ed',
    info: '#F8F8F8',
    warning: '#fff4e5',
    danger: '#fdecea',
    extreme: '#dc3545',
};
var AlertWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  padding: 10px 12px;\n  margin: 12px 0;\n  font-size: 0.825rem;\n  text-align: left;\n  display: flex;\n  position: relative;\n\n  p {\n    margin: 0 0 0 4px;\n  }\n\n  strong {\n    font-weight: 700;\n  }\n\n  svg {\n    width: 22px;\n    fill: ", ";\n    display: inline-block;\n    vertical-align: middle;\n    margin-right: 2px;\n    margin-top: -1px;\n  }\n"], ["\n  background-color: ", ";\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  color: ", ";\n  border-radius: ", ";\n  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),\n    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);\n  padding: 10px 12px;\n  margin: 12px 0;\n  font-size: 0.825rem;\n  text-align: left;\n  display: flex;\n  position: relative;\n\n  p {\n    margin: 0 0 0 4px;\n  }\n\n  strong {\n    font-weight: 700;\n  }\n\n  svg {\n    width: 22px;\n    fill: ", ";\n    display: inline-block;\n    vertical-align: middle;\n    margin-right: 2px;\n    margin-top: -1px;\n  }\n"])), function (props) {
    return props.type ? bgColor[props.type] : bgColor['success'];
}, function (props) {
    return props.type
        ? LightenDarkenColor(bgColor[props.type], -10)
        : LightenDarkenColor(bgColor['success'], -10);
}, function (props) {
    return props.type === 'extreme'
        ? '#ffffff'
        : props.theme.body.textColor || '#212b36';
}, function (props) { return props.theme.border.radius || '6px'; }, function (props) {
    return props.type ? props.theme[props.type] : props.theme['success'];
});
var CloseButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 1rem;\n  cursor: pointer;\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  padding: 0;\n  display: flex;\n  align-items: center;\n"], ["\n  background: none;\n  border: none;\n  font-size: 1rem;\n  cursor: pointer;\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  padding: 0;\n  display: flex;\n  align-items: center;\n"])));
var icons = {
    success: React.createElement(SuccessOutlineIcon, null),
    info: React.createElement(InfoOutlineIcon, null),
    danger: React.createElement(ErrorOutlineIcon, null),
    warning: React.createElement(WarningOutlineIcon, null),
    close: React.createElement(CloseOutlineIcon, null),
};
export function Alert(props) {
    var _a = React.useState(true), isVisible = _a[0], setIsVisible = _a[1];
    var handleClose = function () {
        setIsVisible(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    if (!isVisible)
        return null;
    return (React.createElement(AlertWrapper, { type: props.type || 'success' },
        React.createElement("div", null, props.icon && icons[props.type]),
        React.createElement("p", null,
            props.title && React.createElement("strong", null, props.title),
            " ",
            props.message),
        props.showCloseButton && (React.createElement(CloseButton, { onClick: handleClose }, icons['close']))));
}
var templateObject_1, templateObject_2;
